/* eslint-disable react/no-danger */
import React from 'react';
import { graphql } from 'gatsby';

import PageWrapper from 'Layouts/PageWrapper';
import SEO from 'Components/SEO';

export default ({ data }) => {
  const { markdownRemark: page } = data;

  return (
    <PageWrapper>
      <SEO title={ page.frontmatter.title } />
      <main className="page__main page__main--simple">
        <h1 className="page__title">{ page.frontmatter.title }</h1>
        <div className="page__main__content page__content" dangerouslySetInnerHTML={ { __html: page.html } } />
      </main>
    </PageWrapper>
  );
};

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
